<template>
    <div class="Derlyconstruct">
        <div class="countdown">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">底层构建</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <button class="count_but fr" @click="submit()" v-show="searchVal<8">提交</button>
            </div>
        </div>
        <div class="Derlyco_div">
            <div class="colle_Content">
                <div class="colle_input clearfix">
                    <strong class="fl">
                        创业项目是：
                    </strong>
                    <span class="fl">
                        <span class="span_span_inp">{{forms.project.name}}</span>
                    </span>
                </div>
                <div class="contess_div clearfix" ref="htmlImg">
                    <ul class="ellipse_ul">
                        <li class="ellipse_left fl">
                            <div class="tit_div">
                                <i class="iconfonts icons-yonghu"></i>
                                <p>用户</p>
                            </div>
                            <div class="ellipse_left_div" v-show="forms.p1.values.length>0">
                                <div class="conten_l_div">
                                    <p class="conten_l_div_ps" v-for="(item,index) in forms.p1.values" :key="index" @mouseover="overp1(index)" @mouseout="outp1()">
                                        <span>{{index+1}}、{{item.value}}</span>
                                        <i class="el-icon-close" 
                                            v-show="item.userId == userId && p1Num==index || form.leader==true && p1Num==index" 
                                            @click="delBox('p1',item)"
                                        ></i>
                                    </p>
                                    <p class="p_i_adds" v-show="forms.p1.values.length>0 && forms.p1.values.length + forms.p3.values.length<5">
                                        <i class="iconfonts icons-jiahao"  @click="adds('p1')"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="_left_div" v-show="forms.p1.values.length==0 && forms.p1.values.length + forms.p3.values.length<5">
                                <i class="iconfonts icons-jiahao" @click="adds('p1',1)"></i>
                            </div>
                            <div class="conten_con_div">
                                <p class="conten_l_div_p" v-for="(item3,index3) in forms.p3.values" :key="index3" @mouseover="overp3(index3)" @mouseout="outp3()">
                                    <span>{{index3+1}}、{{item3.value}}</span>
                                    <i class="el-icon-close" 
                                        v-show="item3.userId1 == userId && p3Num==index3 || item3.userId2 == userId && p3Num==index3 || form.leader==true && p3Num==index3" 
                                        @click="delBox('p3',item3)"
                                    ></i>
                                </p>
                            </div>
                        </li>
                        <li class="ellipse_right fl">
                            <div class="tit_div">
                                <i class="iconfonts icons-uf_customer"></i>
                                <p>客户</p>
                            </div>
                            <div class="ellipse_left_div">
                                <div class="conten_r_div">
                                    <p class="conten_l_div_pss" v-for="(item2,index2) in forms.p2.values" :key="index2" @mouseover="overp2(index2)" @mouseout="outp2()">
                                        <span>{{index2+1}}、{{item2.value}}</span>
                                        <i class="el-icon-close" 
                                            v-show="item2.userId == userId && p2Num==index2 || form.leader==true && p2Num==index2" 
                                            @click="delBox('p2',item2)"
                                        ></i>
                                    </p>
                                    <p class="p_i_adds" v-show="forms.p2.values.length>0 && forms.p2.values.length + forms.p3.values.length<5">
                                        <i class="iconfonts icons-jiahao" @click="adds('p2')"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="_right_div" v-show="forms.p2.values.length==0 && forms.p2.values.length + forms.p3.values.length<5">
                                <i class="iconfonts icons-jiahao"  @click="adds('p2',2)"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 弹窗 -->
            <div class="Derlyl_popup">
                <el-dialog
                    :title="titleName"
                    :visible.sync="dialogVisible"
                    width="500px"
                    :before-close="handleClose"
                    :show-close="false">
                    <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small" @submit.native.prevent >
                        <el-form-item label="内容" prop="value">
                            <el-input v-model="ruleForm.value" placeholder="请输入内容" maxlength="8" show-word-limit @keyup.enter.native="enterInput($event)"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="mini" @click="closei('ruleForm')">取消</el-button>
                        <el-button type="primary" size="mini" @click="subxit('ruleForm')">确定</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'
export default {
    props: ['searchVals'],
    components: {
        html2canvas
    },
    data() {
        return {
            searchVal:0,
            value:'',
            dialogVisible:false,
            titleName:'',
            timer:'',
            p1Num:-1,
            p2Num:-1,
            p3Num:-1,
            countNum:0,
            count:{},
            ruleForm:{
                value:''
            },
            rules:{
                value:[
                    { required: true, message: '内容不能为空', trigger:'blur'}
                ]
            },
            form:{},
            forms:{
                project:{},
                p1:{
                    values:[]
                },
                p2:{
                    values:[]
                },
                p3:{
                    values:[]
                }
            },
            userId:''
        }
    },
    methods: {
        getapp(){
            this.searchVal = this.searchVals
            this.userId = this.$store.state.user_id
            this.axios.StuCprojectgets4({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    var str = this.forms.resource
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
        // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' &&  res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        adds(val,item){
            this.ruleForm.node = val
            if(item==1){
                this.ruleForm.type = 'jnz'
            }else{
                this.ruleForm.type = 'gmz'
            }
            
            if(val == 'p1'){
                this.titleName = '添加用户'
            }else if(val == 'p2'){
                this.titleName = '添加客户'
            }
            this.dialogVisible = true
        },
        //鼠标移入
        overp1(val){
            this.p1Num = val
        },
        outp1(){
            this.p1Num = -1
        },
        //鼠标移入
        overp2(val){
            this.p2Num = val
        },
        outp2(){
            this.p2Num = -1
        },
        //鼠标移入
        overp3(val){
            this.p3Num = val
        },
        outp3(){
            this.p3Num = -1
        },
        subxit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.ruleForm.id = this.$store.state.cla_id
                    this.axios.StuCprojectsets4(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.dialogVisible = false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        // 输入框回车事件
        enterInput(e){
            let _this = this
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                _this.subxit('ruleForm');
            }
        },
        // 提交
        submit(){
            // html2canvas(this.$refs.htmlImg).then(canvas=>{
            //     let dataURL = canvas.toDataURL("image/png");
            //     this.uImgUrl = dataURL;
            //     console.log(this.uImgUrl)
            // })
            this.axios.submits4({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        //删除某一条数据
        delBox(val,item){
            console.log(item)
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                if(val=='p1'){
                    this.axios.StuCprojectdels4({
                        id:this.$store.state.cla_id,
                        token:this.$store.state.token,
                        node:val,
                        value:[item]
                    }).then(res=>{
                        if(res.data.code==0){
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else if(val == 'p2'){
                    this.axios.StuCprojectdels4({
                        id:this.$store.state.cla_id,
                        token:this.$store.state.token,
                        node:val,
                        value:[item]
                    }).then(res=>{
                        if(res.data.code==0){
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else if(val == 'p3'){
                    this.axios.StuCprojectdels4({
                        id:this.$store.state.cla_id,
                        token:this.$store.state.token,
                        node:val,
                        value:[item]
                    }).then(res=>{
                        if(res.data.code==0){
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
        handleClose(done){

        }
    },
    beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        clearInterval(that.scont);
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    mounted() {
        this.getapp()
        this.down()
        let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Derlyconstruct.css';
</style>
<style>
.colle_input .el-input.is-disabled .el-input__inner{
    border: none;
    border-bottom: 2px solid #333;
    border-radius: 0;
    font-size: 17px;
    font-weight: bold;
    min-width: 300px;
    color: #333;
    height: 30px;
    line-height: 30px;
    background: transparent;
}
.Derlyl_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.Derlyl_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.Derlyl_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.Derlyl_popup .el-form-item__content{
    margin-left: 0!important;
}
.Derlyl_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.Derlyl_popup .el-form-item--small.el-form-item{
    margin-bottom: 12px;
}
.Derlyl_popup .el-dialog__footer{
    text-align: center;
}
.Derlyl_popup .el-button--mini{
    width: 80px;
}
</style>